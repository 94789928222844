import React, { useState } from 'react';

const ReservaMesa = ({ lugarSeleccionado }) => {
  // Lógica interna del componente
  const [mostrarPanel, setMostrarPanel] = useState(false);

  const handleClick = async () => {
    // Actualiza el estado para mostrar el contenido
    setMostrarPanel(true);

    // Construir datos del log
    const ahora = new Date();
    const logData = {
      fecha: ahora.toLocaleDateString(),
      hora: ahora.toLocaleTimeString(),
      origen: 'ReservaMesa', // Origen fijo, o puedes parametrizarlo si lo requieres
      enlace: lugarSeleccionado?.enlace_reserva || 'Sin enlace'
    };

    // Llamada al endpoint para guardar el log
    try {
      await fetch('https://menuteca.es/api/logReservation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(logData)
      });
      console.log('Log guardado:', logData);
    } catch (error) {
      console.error('Error al guardar el log:', error);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <button
        onClick={handleClick}
        style={{
          color: 'white',
          backgroundColor: '#7E7B46',
          border: 'none',
          padding: '10px 20px',
          fontSize: '20px',
          cursor: 'pointer',
          margin: '20px auto',
          display: 'block',
          borderRadius: '5px',
          transition: 'background-color 0.3s ease',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)'
        }}
      >
        Reservar mesa
      </button>

      {mostrarPanel &&
        (lugarSeleccionado?.enlace_reserva ? (
          <div style={{ marginTop: '20px', height: '400px' }}>
            <iframe
              src={lugarSeleccionado.enlace_reserva}
              title="Reserva Mesa"
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </div>
        ) : (
          <div
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              fontSize: '20px',
              backgroundColor: '#D2B48C', // Color arcilla
              color: 'white',
              borderRadius: '5px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)',
              margin: '20px auto',
              display: 'block'
            }}
          >
            Este restaurante aún no ha incluido su sistema de reservas en Menuteca, puedes probar con el enlace a GoogleMaps o su portal web. <br />¡Gracias por utilizar Menuteca!
          </div>
        ))}
    </div>
  );
};

export default ReservaMesa;

//v3 funcionan menu, 5 filtros, popup y sitio web

import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import './App.css';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Offcanvas, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet-control-geocoder';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import { Range } from "react-range";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
//import EmailForm from './EmailForm';
import CommentApp from './CommentApp';
import { AuthProvider, useAuth } from './AuthContext';
import Login from './Login';
//import Dashboard from './Dashboard';
//import Profile from './Profile';
//import CreateReview from './CreateReview';
import UserButton from './UserButton';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Mapa from './components/Mapa';
import Formulario from './components/Formulario';
import Calendario from './components/Calendario';
//import Demo from './components/Demo';
import Empresas from './components/Empresas';
import Contacto from './components/Contacto';
//import RestaurantForm from './components/RestaurantForm';
import NavBar from './components/NavBar';
//import { useLocation } from 'react-router-dom';
import MenutecaButton from './MenutecaButton';
import ReservaMesa from './ReservaMesa';

/*
const AppContent = () => {
    const { user, loading } = useAuth();
  
    if (loading) return <p>Cargando...</p>;
  
    return (
      <div className="App">
        {user ? (
          <div>
            <Profile />
            <hr />
            <h3>Crear una nueva reseña</h3>
            <CreateReview />
          </div>
        ) : (
          <Login />
        )}
      </div>
    );
  };*/

// Componente para AutocompleteSelect utilizando react-select
function AutocompleteSelect({ opciones, valor, setValor, placeholder }) {
    const opcionesFormateadas = opciones.map(opcion => ({ label: opcion, value: opcion }));

    const manejarCambio = (seleccion) => {
        setValor(seleccion ? seleccion.value : '');
    };

    return (
        <Select
            options={opcionesFormateadas}
            value={opcionesFormateadas.find(op => op.value === valor)}
            onChange={manejarCambio}
            placeholder={placeholder}
            isClearable
            isSearchable
            styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                control: (provided) => ({
                    ...provided,
                    marginBottom: "7px", // Añade margen inferior al componente
                }),
            }}
        />
    );
}

function App() {

  //const mostrarBoton = location.pathname === '/'; // Solo en la ruta raíz

    const [map, setMap] = useState(null);
    const [datos, setDatos] = useState([]);
    const [markers, setMarkers] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [lugarSeleccionado, setLugarSeleccionado] = useState(null);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
/*
    const [lugarSeleccionado, setLugarSeleccionado] = useState({
        "menu vegetariano": 1,
        "menu sin gluten": 0,
        "menu infantil": 1,
        "menu personalizable": 0,
        "buffet libre": 0,
        "carta de alérgenos": 1,
        "bebida": 1,
        "aperitivo": 0,
        "entrante": 1,
        "pan": 0,
        "principal": 1,
        "segundo": 0,
        "postre + café/té": 0,
        "postre": 1,
        "café/té": 0,
      });
*/
      console.log("Lugar seleccionado:", lugarSeleccionado);

      
    // Estados para los filtros
	const [restaurantes, setRestaurantes] = useState([]); //new
    const [localidades, setLocalidades] = useState([]);
    const [subsectores, setSubsectores] = useState([]);
    const [categoriasPrecio] = useState(['Menos de 10€', '10€ - 20€', '20€ - 30€', 'Más de 30€']);
    const [categoriasPuntuacion] = useState(['Menos de 3 estrellas', '3 - 4 estrellas', 'Más de 4 estrellas']);

    const [filtroRestaurante, setFiltroRestaurante] = useState('');
    const [filtroLocalidad, setFiltroLocalidad] = useState('');
    const [filtroSubsector, setFiltroSubsector] = useState('');
    const [filtroPrecio, setFiltroPrecio] = useState('');
    const [filtroPuntuacion, setFiltroPuntuacion] = useState('');
    const [show, setShow] = useState(false);

    // precios
    const [valores, setValores] = useState([0, 50]); // Rango inicial: 0 a 50

    const handleClose = () => setShowModal(false);
    const handleShowOffcanvas = () => setShowOffcanvas(true);
    const handleShow = (lugar) => {
        setLugarSeleccionado(lugar);
        setShowModal(true);
    };
    const handleCloseOffcanvas = () => setShowOffcanvas(false);

    const [isMobile, setIsMobile] = useState(false);
    const [showFilters, setShowFilters] = useState(true);

    const [filtrosVariedades, setFiltrosVariedades] = useState({
        menu_vegetariano: false,
        menu_sin_gluten: false,
        menu_infantil: false,
        menu_personalizable: false,
        buffet_libre: false,
        carta_alergenos: false,
        takeaway: false,
        menupremium: false,
        bebida: false,
        aperitivo: false,
        entrante: false,
        pan: false,
        principal: false,
        segundo: false,
        postre_cafe_te: false,
        postre: false,
        cafe_te: false,
        menu_diario: false,
        menu_diario_noche: false,
        menu_finde: false,
        menu_finde_noche: false,
        // ... otros
      });

    // imágenes

    // checks forzados:
      // Estado inicial de los checkboxes

      

    // Estado para controlar el Lightbox // línea nueva
    const [isLightboxOpen, setIsLightboxOpen] = useState(false); // línea nueva
    const [lightboxIndex, setLightboxIndex] = useState(0); // línea nueva

    // Lista de imágenes para el Lightbox // línea nueva
    const images = [
        lugarSeleccionado?.['imagen 2'],
        lugarSeleccionado?.['imagen 2'],
        lugarSeleccionado?.['imagen 3'],
        lugarSeleccionado?.['menu'],
        lugarSeleccionado?.['ambiente'],
    ].filter(Boolean); // línea nueva

    // Función para abrir el Lightbox en la imagen seleccionada // línea nueva
    const handleImageClick = (index) => {
        setLightboxIndex(index);
        setIsLightboxOpen(true);
    }; // línea nueva


    //scroll

        // Función para manejar el evento de desplazamiento (scroll horizontal)
        const handleScroll = (event) => {
            if (event.deltaX < -30 && !showOffcanvas) {
                // Deslizar hacia la izquierda para abrir el panel
                setShowOffcanvas(true);
            } else if (event.deltaX > 30 && showOffcanvas) {
                // Deslizar hacia la derecha para cerrar el panel
                setShowOffcanvas(false);
            }
        };




    // Inicializar el mapa
    useEffect(() => {
        const mapContainer = document.getElementById('map');
        if (mapContainer && mapContainer._leaflet_id) return;

        const apiKey = process.env.REACT_APP_STADIA_API_KEY;
        //console.log('API Key:', apiKey);

        const newMap = L.map(mapContainer, {
            zoomControl: false // Desactiva el control de zoom predeterminado
        //}).setView([40.416775, -3.703790], 6);
    }).setView([41.38879, 2.16399], 13);
        L.tileLayer(`https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${apiKey}`, {
            maxZoom: 18,
            attribution: '&copy; Stadia Maps'
        }).addTo(newMap);

            // Añadir los controles de zoom en la parte inferior derecha
    L.control.zoom({
        position: 'bottomright' // Cambia la posición a la esquina inferior derecha
    }).addTo(newMap);
		
		    // Inicializar el proveedor y el control de búsqueda
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
        provider,
        style: 'bar',
        autoClose: true,
        retainZoomLevel: false,
        keepResult: true,
		animateZoom: true,
        showMarker: false, // No mostrar el marcador
        showPopup: false,  // No mostrar el popup con "Mark"
    });

// Define el control personalizado para el botón de filtro
const FilterControl = L.Control.extend({
  options: {
    position: 'topright'  // Puedes cambiarlo a 'topleft', 'bottomleft', 'bottomright'
  },

  onAdd: function (map) {
    // Crea un contenedor <div> para el control con la clase "filter-button"
    const container = L.DomUtil.create('div', 'filter-button');

    // Aplica estilos directamente para que se parezca al botón deseado
    container.style.backgroundColor = 'white'; //'#c7be36'
    container.style.color = 'white';
    container.style.padding = '10px 10px';
    container.style.borderRadius = '5px';
    container.style.cursor = 'pointer';
    container.style.display = 'flex';
    container.style.alignItems = 'center';
    container.style.justifyContent = 'center';
    container.style.fontSize = '0'; // Si solo usas una imagen, quita el texto
    //container.style.backgroundColor = isHovered ? '#c7be36' : '#fff'; // cambia al color dorado suave cuando se hace hover

    // Inserta la imagen en el contenedor
    container.innerHTML = `
      <img 
        src="/archivos/filtro2.png" 
        alt="Más" 
        class="transparent-icon" 
        style="width:25px; height:25px; background-color: transparent;" 
      />
    `;

    // Evita que los eventos de clic se propaguen al mapa (para evitar, por ejemplo, que se active el arrastre)
    L.DomEvent.disableClickPropagation(container);

    // Agrega el listener para manejar el clic
    container.addEventListener('click', function (e) {
      // Aquí llamas a tu función para mostrar el offcanvas o filtro
      handleShowOffcanvas();
    });

    return container;
  }
});

// Agrega el control de filtro (botón) al mapa
newMap.addControl(new FilterControl());

    
    // Agregar el control de búsqueda al mapa
    newMap.addControl(searchControl);
	
    //    setMap(newMap);
        //setMarkers([].addTo(newMap));
      //  setMarkers(L.markerClusterGroup().addTo(newMap));
    //}, []);

    // test

// Crear grupo de clústeres con configuración básica
const clusterGroup = L.markerClusterGroup({
    maxClusterRadius: 17, // Reducir el radio máximo de agrupamiento
    iconCreateFunction: (cluster) => {
        const count = cluster.getChildCount(); // Número de marcadores en el clúster
        return L.divIcon({
            html: `<div style="background-color:rgba(199, 189, 54, 0.75) ;
                              color: white; 
                              border-radius: 80%; 
                              width: 20px; 
                              height: 20px; 
                              display: flex; 
                              align-items: center; 
                              justify-content: center;">
                        ${count}
                   </div>`,
            className: 'custom-cluster-icon', // Clase CSS (opcional)
            iconSize: [24, 24], // Tamaño del icono del clúster
        }); // rgba(0, 0, 255, 0.5); 
    },
});

    // Añadir marcadores al grupo de clústeres
    const markersArray = [
        L.marker([41.38879, 2.15899], { icon: iconoPersonalizado }),
        L.marker([41.39, 2.16], { icon: iconoPersonalizado }),
        L.marker([41.385, 2.18], { icon: iconoPersonalizado }),
    ];
    clusterGroup.addLayers(markersArray);

    // Agregar grupo de clústeres al mapa
    newMap.addLayer(clusterGroup);

    // Actualizar estados
    setMap(newMap);
    setMarkers(clusterGroup);
}, []);

// fin test





    const iconoPersonalizado = new L.Icon({
        iconUrl: 'https://menuteca.es/archivos/logo3.png',
        iconSize: [24, 24],
        iconAnchor: [12, 24],
        popupAnchor: [0, -24]
    });

    const [selectedFile, setSelectedFile] = useState('');


    // Cargar datos desde el archivo Excel
    const cargarDatos = (archivo) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const hoja = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(hoja);
            setDatos(jsonData);

            // Extraer datos únicos
            const restaurantesUnicos = [...new Set(jsonData.map(item => item.nombre))].filter(Boolean);
            const localidadesUnicas = [...new Set(jsonData.map(item => item.localidad))].filter(Boolean);
            const subsectoresUnicos = [...new Set(jsonData.map(item => item.subsector))].filter(Boolean);

            console.log("Restaurantes:", restaurantesUnicos);
            console.log("Localidades:", localidadesUnicas);
            console.log("Subsectores:", subsectoresUnicos);
        };
        reader.readAsArrayBuffer(archivo);
    };

    // Función para cargar datos desde el PHP
    const cargarDatosDesdePHP_old = async () => {
        try {
            const response = await fetch(`https://menuteca.es/backend/consultar.php?token=${apiKey_bbdd}`);
            const text = await response.text();
            console.log("Respuesta cruda del servidor:", text);
            
            const jsonData = JSON.parse(text);
            setDatos(jsonData);
        } catch (error) {
            console.error("Error al cargar datos desde PHP:", error);
        }
    };

    //const apiKey_bbdd = process.env.REACT_APP_BBDD_KEY;
    const apiKey_bbdd='mi_token_super_secreto'


    
    
    // Función para cargar datos desde el PHP
const cargarDatosDesdePHP = async () => {
    try {
        const response = await fetch('https://menuteca.es/backend/consultar.php', {
            //const response = await fetch('/backend/consultar.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Authorization': `Bearer ${apiKey_bbdd}` // Enviar el token en el encabezado
            }
        });
        if (!response.ok) throw new Error('Error al conectar con la base de datos');
        
        const jsonData = await response.json();
        setDatos(jsonData);

        // Extraer datos únicos para los filtros
        const restaurantesUnicos = [...new Set(jsonData.map(item => item.nombre))].filter(Boolean);
        const localidadesUnicas = [...new Set(jsonData.map(item => item.localidad))].filter(Boolean);
        const subsectoresUnicos = [...new Set(jsonData.map(item => item.subsector))].filter(Boolean);

        setRestaurantes(restaurantesUnicos);
        setLocalidades(localidadesUnicas);
        setSubsectores(subsectoresUnicos);

        console.log("Datos cargados desde la base de datos");
    } catch (error) {
        console.error("Error al cargar datos desde PHP:", error);
    }
};



    const handleFileChange = (event) => {
        const archivo = event.target.files[0];
        if (archivo) cargarDatos(archivo);
    };

    const handlePredefinedFileLoad = (ruta) => {
        fetch(ruta)
            .then(response => response.arrayBuffer())
            .then(data => {
                const workbook = XLSX.read(data, { type: 'array' });
                const hoja = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(hoja);
                setDatos(jsonData);
                console.log("Datos cargados desde:", ruta);
            })
            .catch(err => console.error("Error al cargar el archivo:", err));
    };

	const filtrarDatos = (lugar) => {
		console.log("Aplicando filtros...", filtroRestaurante, filtroLocalidad, filtroSubsector, filtroPrecio, filtroPuntuacion);

		const filtrarPorPrecio = (precio) => {
			if (filtroPrecio === 'Menos de 10€') return parseFloat(precio) < 10;
			if (filtroPrecio === '10€ - 20€') return parseFloat(precio) >= 10 && parseFloat(precio) <= 20;
			if (filtroPrecio === '20€ - 30€') return parseFloat(precio) > 20 && parseFloat(precio) <= 30;
			if (filtroPrecio === 'Más de 30€') return parseFloat(precio) > 30;
			return true;
		};

        const filtrarPorRangoPrecios = (precio) => { 
            const [min, max] = valores; 
            return parseFloat(precio) >= min && parseFloat(precio) <= max; 
        };

		const filtrarPorPuntuacion = (puntuacion) => {
			if (filtroPuntuacion === 'Menos de 3 estrellas') return parseFloat(puntuacion) < 3;
			if (filtroPuntuacion === '3 - 4 estrellas') return parseFloat(puntuacion) >= 3 && parseFloat(puntuacion) < 4;
			if (filtroPuntuacion === 'Más de 4 estrellas') return parseFloat(puntuacion) >= 4;
			return true;
		};

		return (
			(filtroRestaurante === '' || lugar.nombre?.toLowerCase().includes(filtroRestaurante.toLowerCase())) &&
			(filtroLocalidad === '' || lugar.localidad?.toLowerCase().includes(filtroLocalidad.toLowerCase())) &&
			(filtroSubsector === '' || lugar.subsector?.toLowerCase().includes(filtroSubsector.toLowerCase())) &&
			filtrarPorPrecio(lugar['Precio comida 1']) &&
			filtrarPorPuntuacion(lugar['Puntuación google maps'])
            && 
            filtrarPorRangoPrecios(lugar['Precio comida 1']) &&
            (filtrosVariedades.menu_vegetariano ? lugar.menu_vegetariano === "1" : true) &&
            (filtrosVariedades.menu_sin_gluten ? lugar.menu_sin_gluten === "1" : true) &&
            (filtrosVariedades.menu_infantil ? lugar.menu_infantil === "1" : true) &&
            (filtrosVariedades.menu_personalizable ? lugar.menu_personalizable === "1" : true) &&
            (filtrosVariedades.buffet_libre ? lugar.buffet_libre === "1" : true) &&
            (filtrosVariedades.carta_alergenos ? lugar.carta_alergenos === "1" : true) &&
            (filtrosVariedades.bebida ? lugar.bebida === "1" : true) &&
            (filtrosVariedades.aperitivo ? lugar.aperitivo === "1" : true) &&
            (filtrosVariedades.pan ? lugar.pan === "1" : true) &&
            (filtrosVariedades.entrante ? lugar.entrante === "1" : true) &&
            (filtrosVariedades.principal ? lugar.principal === "1" : true) &&
            (filtrosVariedades.segundo ? lugar.segundo === "1" : true) &&
            (filtrosVariedades.postre_cafe_te ? lugar.postre_cafe_te === "1" : true) &&
            (filtrosVariedades.postre ? lugar.postre === "1" : true) &&
            (filtrosVariedades.cafe_te ? lugar.cafe_te === "1" : true) &&
            (filtrosVariedades.menu_diario ? lugar.menu_diario === "1" : true) &&
            (filtrosVariedades.menu_diario_noche ? lugar.menu_diario_noche === "1" : true) &&
            (filtrosVariedades.menu_finde ? lugar.menu_finde === "1" : true) &&
            (filtrosVariedades.menu_finde_noche ? lugar.menu_finde_noche === "1" : true) 
            //Object.entries(filtrosVariedades).every(([key, value]) => {
            //    return value ? lugarSeleccionado[key] === "1" : true;
            //  })
		);
	};


    useEffect(() => {
        if (datos.length > 0) {
            const restaurantesUnicos = [...new Set(datos.map(item => item.nombre))].filter(Boolean);
            const localidadesUnicas = [...new Set(datos.map(item => item.localidad))].filter(Boolean);
            const subsectoresUnicos = [...new Set(datos.map(item => item.subsector))].filter(Boolean);
    
            setRestaurantes(restaurantesUnicos);
            setLocalidades(localidadesUnicas);
            setSubsectores(subsectoresUnicos);
        }
    }, [datos]);
    

    // Inicializar el mapa
    useEffect(() => {
        const mapContainer = document.getElementById('map');
        if (mapContainer && mapContainer._leaflet_id) return;

        const newMap = L.map(mapContainer, {
            zoomControl: false // Desactiva el control de zoom predeterminado
        }).setView([40.416775, -3.703790], 6);
        L.tileLayer(`https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${apiKey}`, {
            maxZoom: 18,
            attribution: '&copy; Stadia Maps'
        }).addTo(newMap);

            // Añadir los controles de zoom en la parte inferior derecha
    L.control.zoom({
        position: 'bottomright' // Cambia la posición a la esquina inferior derecha
    }).addTo(newMap);



    setMap(newMap);
    //setMarkers([]);
    setMarkers(L.markerClusterGroup().addTo(newMap));
}, []);





    // Mostrar marcadores en el mapa
useEffect(() => {
    if (map && markers) {
        markers.clearLayers();
        //markers.forEach(marker => marker.addTo(map)); //new pruebas individuales v20250107

        // Filtrar los datos antes de añadir los marcadores
        const datosFiltrados = datos.filter(filtrarDatos);

        datosFiltrados.forEach((lugar) => {
            const latitud = parseFloat(lugar.latitud);
            const longitud = parseFloat(lugar.longitud);
            if (!isNaN(latitud) && !isNaN(longitud)) {
                const marker = L.marker([latitud, longitud], { icon: iconoPersonalizado })
                    .on('click', () => handleShow(lugar));
                markers.addLayer(marker);
            }
        });
    }
}, [datos, map, markers, filtroRestaurante, filtroLocalidad, filtroSubsector, filtroPrecio, filtroPuntuacion, filtrosVariedades, valores]);

    // En móvil, el menú estará cerrado por defecto
    useEffect(() => {
        if (isMobile) {
            setShowFilters(false);
        } else {
            setShowFilters(true);
        }
    }, [isMobile]);

    // precios
    //const [valores, setValores] = useState([0, 50]); // Rango inicial: 0 a 50

    return (
      <>
      <AuthProvider>
        <Router>
      <div>
        <Routes>
          <Route path="/" element={<Mapa />} />
          <Route path="/formulario" element={<Formulario />} />
          <Route path="/calendario" element={<Calendario />} />
          {/*<Route path="/demo" element={<Demo />} />*/}
          <Route path="/empresas" element={<Empresas />} />
          <Route path="/contacto" element={<Contacto />} />
         {/* <Route path="/registro" element={<RestaurantForm />} />*/}
        </Routes>
        {/* El menú flotante se mantiene en la esquina inferior izquierda */}
        <NavBar />
        {/*<FloatingMenu />*/}
      </div>
    </Router>
    </AuthProvider>
<AuthProvider>
<UserButton />
</AuthProvider>
<MenutecaButton />
      {/*  <Button
    variant="primary"
    onClick={handleShowOffcanvas}
    className="floating-button">
    <img
        src="/archivos/filtro2.png"
        alt="Más"
        style={{
            width: '20px',
            height: '20px'
            //objectFit: 'contain',
            ,backgroundColor: 'transparent' 
        }}
    />
</Button> *desactivado 20250212/} 

            {/*<Button variant="primary" onClick={handleShowOffcanvas} style={{ margin: '10px' }}>Filtros</Button>
           */} 
                     
                        {/* Cabecera con los logos */} 
                     {/*   <div className="header-container"> */} 
              {/*  <img src={`/archivos/logo1.png`} alt="Logo 1" className="logo" />
                <img src={`/archivos/logo2.png`} alt="Logo 2" className="logo" /> */}
           {/* </div> */} 
{/*
            <Button variant="secondary" onClick={cargarDatosDesdePHP} style={{ marginTop: '10px' }}>
                Cargar datos desde la base de datos
            </Button>
*/} 
        {useEffect(() => {
            cargarDatosDesdePHP();
        }, [])}


            
           <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title style={{ marginBottom: '0' }}>Filtrar por</Offcanvas.Title>
                </Offcanvas.Header>  
                <hr />
                <Offcanvas.Body>
                    {/* Selección de archivo predefinido */}
                    {/*<Form.Select onChange={(e) => handlePredefinedFileLoad(e.target.value)}>
                        <option value="">-- Seleccionar archivo predefinido --</option>
                        {archivosPredefinidos.map((archivo, index) => (
                            <option key={index} value={archivo.ruta}>{archivo.nombre}</option>
                        ))}
                    </Form.Select> */}
                    {/* Opción de cargar archivo personalizado */}
                  {/*  <Form.Control type="file" onChange={handleFileChange} />
                  */}  <AutocompleteSelect opciones={restaurantes} valor={filtroRestaurante} setValor={setFiltroRestaurante} placeholder="Restaurante" style={{ marginBottom: "10px" }}/>
                    <AutocompleteSelect opciones={localidades} valor={filtroLocalidad} setValor={setFiltroLocalidad} placeholder="Localidad" style={{ marginBottom: "10px" }}/>
                    <AutocompleteSelect opciones={subsectores} valor={filtroSubsector} setValor={setFiltroSubsector} placeholder="Tipo de cocina" style={{ marginBottom: "10px" }}/>
                   {/* <AutocompleteSelect opciones={categoriasPrecio} valor={filtroPrecio} setValor={setFiltroPrecio} placeholder="Precio menú" /> */}
                    <AutocompleteSelect opciones={categoriasPuntuacion} valor={filtroPuntuacion} setValor={setFiltroPuntuacion} placeholder="Puntuación Google Maps" style={{ marginBottom: "10px" }}/>
                    
                    <div>

                    <div style={{ marginTop: "20px" }}> {/* Agrega un margen superior */}
    <h5 style={{ marginBottom: "5px" }}>Precio menú</h5> {/* Ajusta el margen inferior */}
    <div style={{ marginBottom: "10px", fontSize: "16px" }}>
        {valores[0]} € - {valores[1]} €
    </div>
</div>

            <Range
                step={1}
                min={0}
                max={50}
                values={valores}
                onChange={(nuevoRango) => setValores(nuevoRango)} // Actualiza los valores dinámicamente
                renderTrack={({ props, children }) => {
                    const { key, ...restProps } = props; // Filtra "key" del objeto "props"
                    const { min, max } = props;

                    const porcentajeMin = ((valores[0] - 0) / (50 - 0)) * 100;
                    const porcentajeMax = ((valores[1] - 0) / (50 - 0)) * 100;
                    console.log(`porcentajeMin: ${porcentajeMin}, porcentajeMax: ${porcentajeMax}`);

                    return (
                        <div
                            {...restProps} // Esparce las demás propiedades sin "key"
                            style={{
                                ...restProps.style,
                                height: "4px",
                                width: "100%",
                                background: `linear-gradient(   
                                    to right,
                                    #ddd 0%,
                                    #ddd ${porcentajeMin}%,
                                    green ${porcentajeMin}%,
                                    green ${porcentajeMax}%,
                                    #ddd ${porcentajeMax}%,
                                    #ddd 100%
                                )`,
                                borderRadius: "1px",
                                position: "relative",
                            }}
                        >
                            {React.Children.map(children, (child, index) =>
                                React.cloneElement(child, { key: index }) // Asigna un "key" único a cada hijo
                            )}
                        </div>
                    );
                }}
                renderThumb={({ props }) => {
                    const { key, ...restProps } = props; // Filtra "key" del objeto "props"
                    return (
                        <div
                            {...restProps} // Esparce las demás propiedades sin "key"
                            style={{
                                ...restProps.style,
                                height: "15px",
                                width: "15px",
                                backgroundColor: "green",
                                borderRadius: "50%",
                            }}
                        />
                    );
                }}
            />
        </div>
    <div className="mt-4">
  <style>{`
    .custom-checkbox {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      position: relative;
      font-size: 16px;
    }
    .custom-checkbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      width: 24px;
      height: 24px;
      background-color: #eee;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s;
    }
    /* Al pasar el mouse sobre el label */
    .custom-checkbox:hover .checkmark {
      background-color: #ccc;
    }
    /* Cuando el checkbox esté marcado, cambia el fondo */
    .custom-checkbox input:checked + .checkmark {
      background-color: #28a745;
    }
    /* Ícono de check, oculto por defecto */
    .checkmark svg {
      display: none;
    }
    /* Muestra el ícono cuando el checkbox está marcado */
    .custom-checkbox input:checked + .checkmark svg {
      display: block;
    }
  `}</style>

  <label className="custom-checkbox">
    <input type="checkbox" />
    <span className="checkmark">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="white"
        viewBox="0 0 16 16"
      >
        <path d="M13.485 1.929a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06 0l-3.5-3.5a.75.75 0 0 1 1.06-1.06L6 8.94l6.97-6.97a.75.75 0 0 1 1.06 0z"/>
      </svg>
    </span>
    <span style={{ marginLeft: '8px' }}>
      Cashback 5% (<span style={{ color: '#28a745' }}>beta</span>)
    </span>
  </label>
</div>


                            {/* Nuevos filtros */}
                        <div className="mt-4">
    <h5>Horario</h5>
    <Form.Check type="checkbox" label="Menú mediodía" checked={filtrosVariedades.menu_diario} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, menu_diario: e.target.checked })} />
    <Form.Check type="checkbox" label="Menú noche" checked={filtrosVariedades.menu_diario_noche} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, menu_diario_noche: e.target.checked })} />
    <Form.Check type="checkbox" label="Menú fin de semana" checked={filtrosVariedades.menu_finde} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, menu_finde: e.target.checked })} />
    <Form.Check type="checkbox" label="Menú fin de semana noche" checked={filtrosVariedades.menu_finde_noche} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, menu_finde_noche: e.target.checked })} />
    {/*<Form.Check 
        type="radio" 
        label="Menú mediodía" 
        name="horario" 
        id="menuMediodia" 
    />
    <Form.Check 
        type="radio" 
        label="Menú noche" 
        name="horario" 
        id="menuNoche" 
    />
    <Form.Check 
        type="radio" 
        label="Menú fin de semana" 
        name="horario" 
        id="menuFinDeSemana" 
    />
    <Form.Check 
        type="radio" 
        label="Menú fin de semana noche" 
        name="horario" 
        id="menuFinDeSemanaNoche" 
    />*/}
</div>
        <div className="mt-4">
            <h5>Variedades</h5>
            {/*<Form.Check type="checkbox" label="Menú vegetariano" />
            <Form.Check
  type="checkbox"
  label="Menú vegetariano_123123"
  checked={filtrosVariedades.menu_vegetariano}
  onChange={(e) =>
    setFiltrosVariedades({ ...filtrosVariedades, menu_vegetariano: e.target.checked })
  }
/>*/}
<Form.Check type="checkbox" label="Menú vegetariano" checked={filtrosVariedades.menu_vegetariano} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, menu_vegetariano: e.target.checked })} /> <Form.Check type="checkbox" label="Menú sin gluten" checked={filtrosVariedades.menu_sin_gluten} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, menu_sin_gluten: e.target.checked })} /> <Form.Check type="checkbox" label="Menú infantil" checked={filtrosVariedades.menu_infantil} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, menu_infantil: e.target.checked })} /> <Form.Check type="checkbox" label="Menú personalizable" checked={filtrosVariedades.menu_personalizable} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, menu_personalizable: e.target.checked })} /> <Form.Check type="checkbox" label="Buffet libre" checked={filtrosVariedades.buffet_libre} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, buffet_libre: e.target.checked })} /> <Form.Check type="checkbox" label="Carta de alérgenos" checked={filtrosVariedades.carta_alergenos} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, carta_alergenos: e.target.checked })} /> 
<Form.Check type="checkbox" label="Opción Take Away"/>
<Form.Check type="checkbox" label="Opción Menú Premium"/>
          {/*  <Form.Check type="checkbox" label="Menú sin gluten" />
            <Form.Check type="checkbox" label="Menú infantil" />
            <Form.Check type="checkbox" label="Menú personalizable" />
            <Form.Check type="checkbox" label="Buffet libre" />
            <Form.Check type="checkbox" label="Carta de alérgenos" /> */}
        </div>
        <div className="mt-4">
            <h5>El precio del menú incluye</h5>
            <Form.Check type="checkbox" label="Bebida" checked={filtrosVariedades.bebida} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, bebida: e.target.checked })} />
            <Form.Check type="checkbox" label="Aperitivo" checked={filtrosVariedades.aperitivo} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, aperitivo: e.target.checked })} />
            <Form.Check type="checkbox" label="Entrante" checked={filtrosVariedades.entrante} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, entrante: e.target.checked })} />
            <Form.Check type="checkbox" label="Pan" checked={filtrosVariedades.pan} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, pan: e.target.checked })} />            
            <Form.Check type="checkbox" label="Principal" checked={filtrosVariedades.principal} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, principal: e.target.checked })} />
            <Form.Check type="checkbox" label="Segundo" checked={filtrosVariedades.segundo} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, segundo: e.target.checked })} />
            <Form.Check type="checkbox" label="Postre + café/té" checked={filtrosVariedades.postre_cafe_te} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, postre_cafe_te: e.target.checked })} />
            <Form.Check type="checkbox" label="Postre/café/té" checked={filtrosVariedades.postre} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, postre: e.target.checked })} />
            <Form.Check type="checkbox" label="Café/té" checked={filtrosVariedades.cafe_te} onChange={(e) => setFiltrosVariedades({ ...filtrosVariedades, cafe_te: e.target.checked })} />            
            {/*
            <Form.Check type="checkbox" label="Principal" />
            <Form.Check type="checkbox" label="Segundo" />
            <Form.Check type="checkbox" label="Postre + café/té" />
            <Form.Check type="checkbox" label="Postre" />
            <Form.Check type="checkbox" label="Café/té" /> */}
        </div>
                </Offcanvas.Body>
            </Offcanvas>
            <div id="map" style={{ height: '100vh' }}></div>

            {/* Modal para mostrar la información del punto */}
            <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{lugarSeleccionado?.nombre}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ paddingTop: '5px', paddingBottom: '5px', lineHeight: 1.25, backgroundColor: 'rgba(62, 238, 27, 0.09)', minHeight: '100vh'}}>
                    <p><strong>Dirección:</strong> {lugarSeleccionado?.DIRECCION || 'No disponible'}</p>
                    {/*<p><strong>CP:</strong> {lugarSeleccionado?.CP || 'No disponible'}</p>*/}
                    <p><strong>Localidad:</strong> {lugarSeleccionado?.localidad || 'No disponible'}</p>
                    <p><strong>Tipo de cocina:</strong> {lugarSeleccionado?.subsector || 'No disponible'}</p>
                    <p><strong>Precio menú: </strong> {lugarSeleccionado?.['Precio comida 1'] || 'No disponible'}€</p>
                    <p><strong>Puntuación Google Maps:</strong> {lugarSeleccionado?.['Puntuación google maps'] || 'No disponible'}</p>
                    {/*<p><strong>Teléfono:</strong> {lugarSeleccionado?.TELEFONO1 || 'No disponible'}</p>
                    */}
                  {/*  <p>
                        <strong>Sitio Web: </strong> 
                        {lugarSeleccionado?.SITIO_WEB ? (
                            <a href={lugarSeleccionado.SITIO_WEB} target="_blank" rel="noopener noreferrer">
                                {lugarSeleccionado.SITIO_WEB}
                            </a>
                        ) : 'No disponible'}
                    </p>*/}


                    {lugarSeleccionado?.SITIO_WEB && ( // Solo muestra si lugarSeleccionado.maps tiene un valor
    <p>
      <strong>Sitio Web: </strong> 
        <a href={lugarSeleccionado.SITIO_WEB} target="_blank" rel="noopener noreferrer">
        {lugarSeleccionado.SITIO_WEB}
        </a>
    </p>
)}


                    {lugarSeleccionado?.maps && ( // Solo muestra si lugarSeleccionado.maps tiene un valor
    <p>
        <a href={lugarSeleccionado.maps} target="_blank" rel="noopener noreferrer">
            Google Maps
        </a>
    </p>
)}
    <div>
      {/* Se pasa el objeto lugarSeleccionado como prop al componente ReservaMesa */}
      <ReservaMesa lugarSeleccionado={lugarSeleccionado} />
    </div>
    <div style={{ marginBottom: '25px' }}></div>
                  {/*  <div>
                        {lugarSeleccionado?.['imagen 1'] && (
                            <img src={lugarSeleccionado['imagen 1']} alt="Imagen 1" style={{ width: '100%', marginBottom: '10px' }} />
                        )}
                        {lugarSeleccionado?.['imagen 2'] && (
                            <img src={lugarSeleccionado['imagen 2']} alt="Imagen 2" style={{ width: '100%' }} />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>*/}
                            <div>
                        {/* Al hacer clic en una imagen, abre el Lightbox */}
                        {lugarSeleccionado?.['ambiente'] && (
                            <img
                                src={`https://menuteca.es/archivos/${lugarSeleccionado['ambiente']}`}
                                alt="Ambiente"
                                style={{ width: '100%', cursor: 'pointer' }} // línea nueva
                                //onClick={() => handleImageClick(1)} // línea nueva
                            />
                        )}
                        <p></p>                        
                        Ejemplo reciente del menú:
                        <p></p>
                        {lugarSeleccionado?.['menu'] && (
                            <img
                                src={`https://menuteca.es/archivos/${lugarSeleccionado['menu']}`}
                                alt="Menu"
                                style={{ width: '100%', marginBottom: '10px', cursor: 'pointer' }} // línea nueva
                                //onClick={() => handleImageClick(0)} // línea nueva
                            />
                        )} 
                        {lugarSeleccionado?.['imagen 1'] && (
                            <img
                                src={`https://menuteca.es/archivos/${lugarSeleccionado['imagen 1']}`}
                                alt="Imagen 1"
                                style={{ width: '100%', marginBottom: '10px', cursor: 'pointer' }} // línea nueva
                                //onClick={() => handleImageClick(0)} // línea nueva
                            />
                        )}                                                
                        {lugarSeleccionado?.['imagen 2'] && (
                            <img
                                src={`https://menuteca.es/archivos/${lugarSeleccionado['imagen 2']}`}
                                alt="Imagen 2"
                                style={{ width: '100%', cursor: 'pointer' }} // línea nueva
                                //onClick={() => handleImageClick(1)} // línea nueva
                            />
                        )}
                        {lugarSeleccionado?.['imagen 3'] && (
                            <img
                                src={`https://menuteca.es/archivos/${lugarSeleccionado['imagen 3']}`}
                                alt="Imagen 3"
                                style={{ width: '100%', cursor: 'pointer' }} // línea nueva
                                //onClick={() => handleImageClick(1)} // línea nueva
                            />
                        )}                                                
                     </div>
                     <div>
                     <div className="mt-4">
        <h5>Horario</h5>
        <Form.Check
          type="checkbox"
          label="Menú mediodía"
          checked={+lugarSeleccionado?.["menu_diario"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Menú mediodía noche"
          checked={+lugarSeleccionado?.["menu_diario_noche"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Menú fin de semana"
          checked={+lugarSeleccionado?.["menu_finde"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Menú fin de semana noche"
          checked={+lugarSeleccionado?.["menu_finde_noche"] === 1}
          readOnly
        />
        </div>
      <div className="mt-4">
        <h5>Variedades</h5>
        <Form.Check
          type="checkbox"
          label="Menú vegetariano"
          checked={+lugarSeleccionado?.["menu_vegetariano"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Menú sin gluten"
          checked={+lugarSeleccionado?.["menu_sin_gluten"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Menú infantil"
          checked={+lugarSeleccionado?.["menu_infantil"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Menú personalizable"
          checked={+lugarSeleccionado?.["menu_personalizable"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Buffet libre"
          checked={+lugarSeleccionado?.["buffet_libre"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Carta de alérgenos"
          checked={+lugarSeleccionado?.["carta_alergenos"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Opción take away"
          checked={+lugarSeleccionado?.["takeaway"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Opción menú premium"
          checked={+lugarSeleccionado?.["menupremium"] === 1}
          readOnly
        />
      </div>
      <div className="mt-4">
        <h5>El precio del menú incluye</h5>
        <Form.Check
          type="checkbox"
          label="Bebida"
          checked={+lugarSeleccionado?.["bebida"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Aperitivo"
          checked={+lugarSeleccionado?.["aperitivo"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Entrante"
          checked={+lugarSeleccionado?.["entrante"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Pan"
          checked={+lugarSeleccionado?.["pan"] === "1"}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Principal"
          checked={+lugarSeleccionado?.["principal"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Segundo"
          checked={+lugarSeleccionado?.["segundo"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Postre + café/té"
          checked={+lugarSeleccionado?.["postre_cafe_te"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Postre/café/té"
          checked={+lugarSeleccionado?.["postre"] === 1}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Café/té"
          checked={+lugarSeleccionado?.["cafe_te"] === 1}
          readOnly
        />
      </div>
    </div>
 {/*     <div>
      <h5> <br />
        Envia correo a Menuteca</h5>
      <EmailForm />
    </div>  */}
 {/*<div>
      <h4>Reseñas (beta)</h4>
      <CommentApp />
    </div>*/}
    <br />
     <div className="App">
     <h5> Inicia sesión para obtener descuentos </h5>
     <Login />
    </div>
 {/*   <AuthProvider>
    <CommentApp lugarSeleccionado={lugarSeleccionado} />
    </AuthProvider>*/}

<AuthProvider>
  <div style={{ backgroundColor: 'rgba(116, 83, 23, 0.18)', minHeight: '100vh' }}>
    {/* El botón de usuario se muestra siempre en la esquina superior izquierda */}
    {/* El resto de la aplicación, por ejemplo, CommentApp */}
    <CommentApp lugarSeleccionado={lugarSeleccionado} />
  </div>
</AuthProvider>

               {/*    
        <div className="mt-4">
            <h5>Variedades</h5>
            <Form.Check type="checkbox" label="Menú vegetariano" />
            <Form.Check type="checkbox" label="Menú sin gluten" />
            <Form.Check type="checkbox" label="Menú infantil" />
            <Form.Check type="checkbox" label="Menú personalizable" />
            <Form.Check type="checkbox" label="Buffet libre" />
            <Form.Check type="checkbox" label="Carta de alérgenos" />
        </div>
        <div className="mt-4">
            <h5>El precio del menú incluye</h5>
            <Form.Check type="checkbox" label="Bebida" />
            <Form.Check type="checkbox" label="Aperitivo" />
            <Form.Check type="checkbox" label="Entrante" />
            <Form.Check type="checkbox" label="Pan" />
            <Form.Check type="checkbox" label="Principal" />
            <Form.Check type="checkbox" label="Segundo" />
            <Form.Check type="checkbox" label="Postre + café/té" />
            <Form.Check type="checkbox" label="Postre" />
            <Form.Check type="checkbox" label="Café/té" />
        </div> */}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Lightbox para las imágenes */}
            {isLightboxOpen && ( // línea nueva
                <Lightbox
                    mainSrc={images[lightboxIndex]} // línea nueva
                    nextSrc={images[(lightboxIndex + 1) % images.length]} // línea nueva
                    prevSrc={images[(lightboxIndex + images.length - 1) % images.length]} // línea nueva
                    onCloseRequest={() => setIsLightboxOpen(false)} // línea nueva
                    onMovePrevRequest={() =>
                        setLightboxIndex((lightboxIndex + images.length - 1) % images.length) // línea nueva
                    } // línea nueva
                    onMoveNextRequest={() =>
                        setLightboxIndex((lightboxIndex + 1) % images.length) // línea nueva
                    } // línea nueva
                />
            )}
        </>
    );
}

export default App;
// src/components/Empresas.js
import React from 'react';

const Empresas = () => {
  // Contenedor general con fondo similar al del formulario (AliceBlue: #f0f8ff)
  const containerStyle = {
    minHeight: '100vh',
    backgroundColor: '#EAE6E1', //7E7B46 f0f8ff
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px 0px',
  };

  // Tarjeta central con fondo blanco, bordes redondeados y sombra sutil
  const cardStyle = {
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    width: '100%',
  };

  // Estilo para los títulos y subtítulos
  const headingStyle = {
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '16px',
    color: '#583B1F'
  };

  const subHeadingStyle = {
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '8px',
    backgroundColor: '#EAE6E1',
    paddingLeft: '10px',
    borderRadius: '5px',
        height: '300%',
        color: '#7E7B46'
  };

  // Estilo para los párrafos
  const paragraphStyle = {
    color: '#333',
    lineHeight: '1.6',
    marginBottom: '16px',
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <h1 style={{ ...headingStyle, fontSize: '2.25rem' }}>Empresas</h1>
        <h2 style={{ ...subHeadingStyle, fontSize: '1.5rem' }}>
          ¿Qué ofrecemos actualmente?
        </h2>
        <div>
          <p style={paragraphStyle}>
            🗺️ <strong>Un mapa interactivo:</strong><br />
            Encuentra restaurantes clasificados por tipo de cocina, ubicación o precio del menú, entre otros filtros.
          </p>
          <p style={paragraphStyle}>
            🍽️ <strong>El mejor menú del día:</strong><br />
            Explora opciones actualizadas de menús del día para cada gusto y presupuesto, todo en un solo lugar.
          </p>
          <p style={paragraphStyle}>
            📝 <strong>Registro fácil para restaurantes:</strong><br />
            Registra tu restaurante mediante nuestro formulario y muestra tu menú a miles de clientes potenciales.
          </p>
          <p style={paragraphStyle}>
            📢 <strong>Promoción personalizada:</strong><br />
            Promociona menús destacados a través de nuestra newsletter y redes sociales para llegar a más comensales interesados.
          </p>
        </div>
        <h2 style={{ ...subHeadingStyle, fontSize: '1.5rem', marginTop: '32px' }}>
          ¿Otras propuestas?
        </h2>
        <div>
          <p style={paragraphStyle}>
            🏪 <strong>Restaurantes y locales:</strong><br />
            Promociona tu menú del día, ofertas especiales o eventos para atraer más clientes y aumentar reservas.
          </p>
          <p style={paragraphStyle}>
            🎉 <strong>Eventos e inauguraciones:</strong><br />
            Asegura el éxito de tus eventos gastronómicos o aperturas llegando a un público segmentado.
          </p>
          <p style={paragraphStyle}>
            🍷 <strong>Productos y proveedores gastronómicos:</strong><br />
            Destaca productos como carnes, vinos, alimentos gourmet o servicios de catering, y conecta con restaurantes y distribuidores.
          </p>
          <p style={paragraphStyle}>
            🚛 <strong>Logística y transporte:</strong><br />
            Promociona servicios de transporte refrigerado, distribución de alimentos o soluciones logísticas específicas para el sector.
          </p>
          <p style={paragraphStyle}>
            ❄️ <strong>Refrigeración y equipos de cocina:</strong><br />
            Llega a restaurantes y negocios que necesiten equipamiento especializado, como cámaras frigoríficas, hornos industriales o utensilios profesionales.
          </p>
          <p style={paragraphStyle}>
            ✅ <strong>Control de calidad y certificaciones:</strong><br />
            Ofrece servicios de auditoría, normativas de calidad, etiquetado o cumplimiento sanitario.
          </p>
          <p style={paragraphStyle}>
            📣 <strong>Promociones y expansión:</strong><br />
            Apoya a negocios en sus estrategias de marketing, lanzamientos de productos o expansión a nuevas ciudades o mercados.
          </p>
          <p style={paragraphStyle}>
            💬 Escríbenos hoy mismo y descubre cómo podemos colaborar para impulsar tu propuesta y conectar con clientes interesados en tus servicios.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Empresas;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Estilo para la barra de navegación
const navBarStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: '#7E7B46', // Fondo marrón
  color: '#EAE6E1',           // Letras en claro
  zIndex: 1000,
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  fontFamily: '"Young Serif", serif', // Tipo de letra Young Serif
  fontSize: '1.2em',                    // Un poco más grande
  fontWeight: 'bold'
};

// Estilos base para los enlaces
const linkBaseStyle = {
  color: '#EAE6E1',
  textDecoration: 'none',
  padding: '10px 15px',
  transition: 'color 0.3s ease'
};

// Estilo que se aplica al hacer hover o click
const hoverActiveStyle = {
  color: '#583B1F'
};

// Componente para cada enlace que maneja el hover con estado
const NavLinkItem = ({ to, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const combinedStyle = {
    ...linkBaseStyle,
    ...(isHovered ? hoverActiveStyle : {})
  };

  return (
    <Link 
      to={to} 
      style={combinedStyle} 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </Link>
  );
};

const NavBar = () => {
  return (
    <div style={navBarStyle}>
<nav style={{ display: 'flex', width: '100%', justifyContent: 'space-around', gap: '0px', marginLeft: '35px', marginRight: '35px'
 }}>
        <NavLinkItem to="/" style={{ padding: '0 5px' }}>Mapa</NavLinkItem>
        <NavLinkItem to="/empresas" style={{ padding: '0 5px' }}>Menuteca</NavLinkItem>
        <NavLinkItem to="/formulario" style={{ padding: '0 5px' }}>Formulario</NavLinkItem>
        {/*<NavLinkItem to="/calendario">Calendario</NavLinkItem>*/}
        {/* <NavLinkItem to="/demo">Demo</NavLinkItem> */}
      {/* <NavLinkItem to="/contacto" style={{ padding: '0 5px' }}>Menuteca B2B</NavLinkItem>*/}
      </nav>
    </div>
  );
};

export default NavBar;

// src/components/Calendario.js
import React from 'react';

const Calendario = () => {
  const containerStyle = {
    paddingTop: '80px',
    padding: '40px'
  };
  const calendarBoxStyle = {
    border: '1px solid #ccc',
    padding: '10px',
    marginTop: '20px'
  };
  return (
    <div style={containerStyle}>
      <h1>Calendario de Eventos Gastronómicos</h1>
      <p>
        🥳📅 Bienvenidos al Calendario de Eventos Gastronómicos 🍴🎉<br />
        🔍 Aquí podrás descubrir las mejores inauguraciones, promociones especiales, ferias gastronómicas y mucho más, ¡todo a tu alcance! 🌟<br />
        🛠️ Actualmente estamos construyendo esta sección para ofrecerte una experiencia única con filtros temáticos y geográficos, que te permitirán encontrar eventos adaptados a tus intereses.<br />
        📢 ¿Tienes un evento que quieras destacar? ¡Contáctanos! 💌 Estaremos encantados de incluirlo en nuestro calendario para que brille como se merece.
      </p>
      {/* Filtros de ejemplo */}
      <div>
        <label>
          Ciudad:
          <select style={{ marginLeft: '10px' }}>
            <option value="">Seleccione</option>
            <option value="barcelona">Barcelona</option>
            <option value="madrid">Madrid</option>
            {/* Más opciones */}
          </select>
        </label>
        <label style={{ marginLeft: '20px' }}>
          Tipo de Evento:
          <select style={{ marginLeft: '10px' }}>
            <option value="">Seleccione</option>
            <option value="inauguracion">Inauguración</option>
            <option value="promocion">Promoción</option>
            <option value="feria">Feria</option>
          </select>
        </label>
      </div>
      <div style={calendarBoxStyle}>
        [Calendario: vista mensual mostrando la fecha actual]
      </div>
    </div>
  );
};

export default Calendario;

import React, { useState } from 'react';
import UserPanel from './UserPanel';

const UserButton = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    position: 'fixed',
    top: '3px',
    left: '10px',
    backgroundColor: isHovered ? '#c7be36' : '#fff', // cambia al color dorado suave cuando se hace hover
    border: 'none',
    borderRadius: '50%',
    padding: '4px',
    cursor: 'pointer',
    zIndex: 1000,
    boxShadow: '0 0 5px rgba(0,0,0,0.3)',
    transition: 'background-color 0.3s ease'
  };

  return (
    <>
      <button 
        onClick={() => setIsPanelOpen(true)}
        style={buttonStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
<img 
  src="https://menuteca.es/archivos/login.png" 
  alt="Acceder al panel de usuario" 
  width="24" 
  height="24" 
  style={{ backgroundColor: 'transparent' }}
/>

      </button>
      {isPanelOpen && <UserPanel onClose={() => setIsPanelOpen(false)} />}
    </>
  );
};

export default UserButton;

// Login.js
import React from 'react';
import { auth, googleAuthProvider } from './firebase';
import { signInWithPopup } from 'firebase/auth';

const Login = () => {
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      console.log('Usuario logueado:', result.user);
      // Aquí ya se actualizará el estado en AuthContext a través de onAuthStateChanged
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
    }
  };

  return (
    <div>
      <button onClick={signInWithGoogle}>Iniciar sesión con Google</button>
    </div>
  );
};

export default Login;

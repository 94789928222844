// src/components/Mapa.js
import React from 'react';

const Mapa = () => {
  const containerStyle = {
    paddingTop: '80px', // para compensar la altura del NavBar
    padding: '20px'
  };
  return (
    <div style={containerStyle}>
      {/*<h1>Mapa</h1>
      <p>Aquí se mostrará el mapa interactivo.</p>*/}
    </div>
  );
};

export default Mapa;

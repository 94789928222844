import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import Login from './Login'; // Tu componente de Login ya implementado
import { auth } from './firebase';

const UserPanel = ({ onClose }) => {
  const { user } = useAuth();
  const [reviews, setReviews] = useState([]);
  const apiKey_bbdd = 'mi_token_super_secreto';

  // Cargar reseñas del backend y filtrarlas por el correo del usuario
  const fetchUserReviews = async () => {
    try {
      const response = await fetch('https://menuteca.es/backend/reviews.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': `Bearer ${apiKey_bbdd}`
        }
      });
      if (!response.ok) {
        throw new Error('Error al conectar con la base de datos');
      }
      const jsonData = await response.json();
      if (user && user.email) {
        const userReviews = jsonData.filter(review => review.user === user.email);
        setReviews(userReviews);
      } else {
        setReviews([]);
      }
    } catch (error) {
      console.error('Error al cargar las reseñas del usuario:', error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserReviews();
    }
  }, [user]);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  // --- Cálculos para la barra de progreso y título de menús ---
  const reviewCount = reviews.length;
  const progressPercentage = Math.min(reviewCount * 10, 100);
  const faltanMenus = reviewCount < 10 ? 10 - reviewCount : 0;

  // --- Agrupar reseñas por fecha ---
  const groupedReviews = reviews.reduce((groups, review) => {
    // Asumimos que review.review_date es una fecha válida
    const date = new Date(review.review_date).toLocaleDateString();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(review);
    return groups;
  }, {});

  // --- Calcular restaurante favorito ---
  let restauranteFavorito = null;
  if (reviews.length > 0) {
    // Convertir ratings a número (en caso de que venga como string)
    const reviewsConRating = reviews.map(r => ({ ...r, rating: Number(r.rating) }));
    const maxRating = Math.max(...reviewsConRating.map(r => r.rating));
    const reviewsMax = reviewsConRating.filter(r => r.rating === maxRating);
    // Seleccionar uno al azar entre los de máxima puntuación
    restauranteFavorito = reviewsMax[Math.floor(Math.random() * reviewsMax.length)].restaurant;
  }

  return (
    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '350px',               // Panel ligeramente más ancho
      height: '100%',
      backgroundColor: '#fff',
      border: '3px solid #fff',
      borderRadius: '15px',
      boxShadow: '2px 0 5px rgba(0,0,0,0.3)',
      zIndex: 2000,
      padding: '20px',
      overflowY: 'auto'
    }}>
      {/* Botón de cierre */}
      <button 
        onClick={onClose} 
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'none',
          border: 'none',
          fontSize: '1.2em',
          cursor: 'pointer'
        }}
      >
        X
      </button>
      
      {/* Cabecera y descripción */}
      <h2 style={{ marginTop: '40px', marginBottom: '5px' }}>Panel de Usuario</h2>
      <p style={{ fontSize: '14px', color: '#555', marginBottom: '20px' }}>
        Valida tus asistencias y hazte con recompensas, cupones y participa en sorteos.
      </p>
                {/* Barra horizontal amarilla */}
                <div style={{
            backgroundColor: 'yellow',
            width: '100%',
            padding: '10px',
            textAlign: 'center',
            marginBottom: '20px'
          }}>
            <span style={{ fontWeight: 'bold' }}>Hazte con un menú gratuito</span>
          </div>
      {/* Barra de progreso */}
      <div style={{ marginBottom: '20px' }}>
        <div style={{
          backgroundColor: '#f5f5dc', // beige
          width: '100%',
          height: '20px',
          borderRadius: '10px',
          overflow: 'hidden'
        }}>
          <div style={{
            backgroundColor: 'green',
            width: `${progressPercentage}%`,
            height: '100%'
          }} />
        </div>
        <p style={{ textAlign: 'center', marginTop: '5px', fontWeight: 'bold' }}>
          {reviewCount < 10
            ? `Estás a ${faltanMenus} menú(s) de una invitación`
            : '¡Has conseguido tu menú de invitación!'}
        </p>
      </div>
      
      {user ? (
        <div>
          {/* Información del usuario y botón para cerrar sesión */}
          <p style={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Bienvenido, {user.displayName || user.email}
          </p>
          <button 
            onClick={handleSignOut}
            style={{
              padding: '10px 15px',
              marginBottom: '20px',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              width: '100%'
            }}
          >
            Cerrar Sesión
          </button>

          {/* Mostrar restaurante favorito si hay reseñas */}
          {restauranteFavorito && (
            <p style={{ marginBottom: '20px', fontStyle: 'italic' }}>
              Tu restaurante favorito ha sido: {restauranteFavorito}
            </p>
          )}

          {/* Reseñas agrupadas por fecha */}
          <h3>Tus reseñas</h3>
          {reviews.length === 0 ? (
            <p>No tienes reseñas registradas.</p>
          ) : (
            Object.keys(groupedReviews)
              .sort((a, b) => new Date(b) - new Date(a))  // Ordenar fechas descendentes
              .map(date => (
                <div key={date} style={{ marginBottom: '15px' }}>
                  <h4 style={{ marginBottom: '5px' }}>{date}</h4>
                  <ul style={{ paddingLeft: '20px' }}>
                    {groupedReviews[date].map(review => (
                      <li key={review.id || review.review_date} style={{ marginBottom: '5px' }}>
                        <strong>{review.restaurant}</strong>: {review.review} ({review.rating} ★)
                      </li>
                    ))}
                  </ul>
                </div>
              ))
          )}
        </div>
      ) : (
        <div>
          {/* Si el usuario no está autenticado, mostramos el Login */}
          <Login />
        </div>
      )}
    </div>
  );
};

export default UserPanel;

import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext'; // Asegúrate de tener un AuthContext configurado

// Componente para la selección visual de estrellas
const StarRating = ({ rating, setRating }) => {
  return (
    <div>
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          onClick={() => setRating(star)}
          style={{
            cursor: 'pointer',
            fontSize: '1.5em',
            color: star <= rating ? 'gold' : 'gray'
          }}
        >
          &#9733;
        </span>
      ))}
    </div>
  );
};

const CommentApp = ({ lugarSeleccionado }) => {
  const { user } = useAuth(); // Obtenemos el usuario logueado (puede ser null)
  
  // Inicializamos el estado del formulario con el nombre del restaurante forzado.
  const [formData, setFormData] = useState({
    usuarioEmail: '',
    restaurante: lugarSeleccionado?.nombre || '',
    mensaje: '',
    rating: 0
  });

  // Si "lugarSeleccionado" cambia, actualizamos el campo "restaurante" automáticamente.
  useEffect(() => {
    if (lugarSeleccionado && lugarSeleccionado.nombre) {
      setFormData((prev) => ({
        ...prev,
        restaurante: lugarSeleccionado.nombre
      }));
    }
  }, [lugarSeleccionado]);

  // Estados para el manejo del ticket y su vista previa
  const [ticketFile, setTicketFile] = useState(null);
  const [ticketPreview, setTicketPreview] = useState('');

  // Estados para el manejo de la imagen y su vista previa
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');

  // Estado para almacenar los comentarios obtenidos de la BD
  const [comments, setComments] = useState([]);

  // Token para la conexión con la BD (según tu configuración)
  const apiKey_bbdd = 'mi_token_super_secreto';

  // Función para cargar todas las reseñas desde reviews.php y filtrar por el restaurante seleccionado
  const cargarReviews = async () => {
    try {
      const response = await fetch('https://menuteca.es/backend/reviews.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization': `Bearer ${apiKey_bbdd}`
        }
      });
      if (!response.ok) {
        throw new Error('Error al conectar con la base de datos');
      }
      const jsonData = await response.json();
      // Filtrar las reseñas para que se muestren solo aquellas que correspondan al restaurante seleccionado.
      if (lugarSeleccionado && lugarSeleccionado.nombre) {
        const reviewsForRestaurant = jsonData.filter(
          review => review.restaurant === lugarSeleccionado.nombre
        );
        setComments(reviewsForRestaurant);
      } else {
        setComments(jsonData);
      }
    } catch (error) {
      console.error('Error al cargar las reseñas:', error);
    }
  };

  // Cargar las reseñas al montar el componente o cuando cambie el usuario o el restaurante seleccionado
  useEffect(() => {
    cargarReviews();
  }, [user, lugarSeleccionado]);

  // Calcular la media y el total de reseñas
  const totalReviews = comments.length;
  const averageRating =
    totalReviews > 0
      ? (
          comments.reduce((acc, cur) => acc + parseInt(cur.rating, 10), 0) /
          totalReviews
        ).toFixed(1)
      : 0;

  // Manejo de cambios en los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  // Manejo de la selección del ticket
  const handleTicketFileChange = (e) => {
    const file = e.target.files[0];
    setTicketFile(file);
    if (file && file.type.startsWith('image/')) {
      const previewUrl = URL.createObjectURL(file);
      setTicketPreview(previewUrl);
    } else {
      setTicketPreview('');
    }
  };

  // Manejo de la selección de la imagen
  const handleImageFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    if (file && file.type.startsWith('image/')) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    } else {
      setImagePreview('');
    }
  };

  // Manejo del envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Si hay usuario, se utiliza su correo; de lo contrario, se usa el valor ingresado manualmente.
    const email = user ? user.email : formData.usuarioEmail;
    
    const formDataToSend = new FormData();
    formDataToSend.append('user', email);
    formDataToSend.append('review', formData.mensaje);
    formDataToSend.append('restaurant', formData.restaurante);
    formDataToSend.append('rating', formData.rating);

    if (ticketFile) {
      formDataToSend.append('attachment', ticketFile);
    }
    if (imageFile) {
      formDataToSend.append('image', imageFile);
    }

    try {
      const response = await fetch('https://menuteca.es/backend/reviews.php', {
        method: 'POST',
        headers: {
          'X-Authorization': `Bearer ${apiKey_bbdd}`
        },
        body: formDataToSend
      });
      if (!response.ok) {
        throw new Error('Error al enviar la reseña');
      }
      const result = await response.json();
      console.log('Respuesta del servidor:', result);
      cargarReviews();
      // Limpiar formulario y vistas previas, forzando nuevamente el nombre del restaurante.
      setFormData({
        usuarioEmail: '',
        restaurante: lugarSeleccionado?.nombre || '',
        mensaje: '',
        rating: 0
      });
      setTicketFile(null);
      setTicketPreview('');
      setImageFile(null);
      setImagePreview('');
      document.getElementById('ticket-input').value = "";
      document.getElementById('image-input').value = "";
    } catch (error) {
      console.error('Error al enviar la reseña:', error);
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <h3>Reseñas</h3>
      {/* Barra horizontal amarilla */}
      <div
        style={{
          backgroundColor: 'gold',
          width: '100%',
          padding: '10px',
          textAlign: 'center',
          marginBottom: '20px'
        }}
      >
        <span style={{ fontWeight: 'bold' }}>Hazte con un menú gratuito</span>
      </div>

      <h6>Valorar</h6>
      {/* Si hay usuario logueado se muestra su correo en un campo deshabilitado,
          de lo contrario se muestra un input para que el usuario ingrese su correo */}
      {user ? (
        <div style={{ marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Tu correo:</label>
          <input
            type="email"
            value={user.email}
            disabled
            style={{ padding: '5px', width: '100%' }}
          />
        </div>
      ) : (
        <div style={{ marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Tu correo:</label>
          <input
            type="email"
            name="usuarioEmail"
            value={formData.usuarioEmail}
            onChange={handleChange}
            required
            style={{ padding: '5px', width: '100%' }}
          />
        </div>
      )}

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div style={{ marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Restaurante:</label>
          {/* Se fuerza el nombre del restaurante y se deshabilita el campo */}
          <input
            type="text"
            name="restaurante"
            value={formData.restaurante}
            disabled
            required
            style={{ padding: '5px', width: '100%' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ verticalAlign: 'top', marginRight: '10px' }}>Comentario:</label>
          <textarea
            name="mensaje"
            value={formData.mensaje}
            onChange={handleChange}
            required
            rows="5"
            style={{ padding: '5px', width: '100%' }}
          ></textarea>
        </div>
        {/* Selección de estrellas */}
        <div style={{ marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Puntuación:</label>
          <StarRating
            rating={formData.rating}
            setRating={(star) => setFormData(prev => ({ ...prev, rating: star }))}
          />
        </div>

        {/* Subida del ticket */}
        <div style={{ marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>
            Subir Ticket: (obtén un 5% en cashback)
          </label>
          <input
            type="file"
            id="ticket-input"
            name="attachment"
            onChange={handleTicketFileChange}
            accept="image/*,application/pdf"
          />
          {ticketPreview && (
            <div style={{ marginTop: '5px' }}>
              <p>Vista previa del ticket:</p>
              <img
                src={ticketPreview}
                alt="Ticket Preview"
                style={{ maxWidth: '100%', maxHeight: '200px' }}
              />
            </div>
          )}
        </div>

        {/* Subida de imagen */}
        <div style={{ marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Subir Imagen:</label>
          <input
            type="file"
            id="image-input"
            name="image"
            onChange={handleImageFileChange}
            accept="image/*"
          />
          {imagePreview && (
            <div style={{ marginTop: '5px' }}>
              <p>Vista previa de la imagen:</p>
              <img
                src={imagePreview}
                alt="Image Preview"
                style={{ maxWidth: '100%', maxHeight: '200px' }}
              />
            </div>
          )}
        </div>

        <button type="submit" style={{ padding: '8px 15px' }}>
          Enviar Comentario
        </button>
      </form>

      <hr style={{ margin: '20px 0' }} />

      <h6>Reseñas</h6>
      <div style={{ marginBottom: '10px' }}>
        <strong>Puntuación media:</strong> {averageRating} <br />
        <strong>Cantidad de reseñas:</strong> {totalReviews}
      </div>

      {comments.length === 0 ? (
        <p>No hay comentarios registrados para este restaurante.</p>
      ) : (
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {comments.map((comment) => (
            <li
              key={comment.id || comment.user + comment.review_date}
              style={{
                marginBottom: '20px',
                borderBottom: '1px solid #ccc',
                paddingBottom: '10px'
              }}
            >
              <p>
                <strong>{comment.restaurant}</strong> <br />
                <em>por {comment.user}</em> - {comment.rating}{' '}
                {comment.rating === 1 ? 'estrella' : 'estrellas'}
              </p>
              <p>{comment.review}</p>

              {/* Mostrar ticket si existe */}
              {comment.attachment && (
                <div>
                  <p>Ticket adjunto:</p>
                  {comment.attachment.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                    <img
                      src={`https://menuteca.es/backend/${comment.attachment}`}
                      alt="Ticket"
                      style={{ maxWidth: '100%', maxHeight: '200px' }}
                    />
                  ) : (
                    <a
                      href={`https://menuteca.es/backend/${comment.attachment}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver archivo
                    </a>
                  )}
                </div>
              )}

              {/* Mostrar imagen si existe */}
              {comment.image_ && (
                <div>
                  <p>Imagen adjunta:</p>
                  <img
                    src={`https://menuteca.es/backend/${comment.image_}`}
                    alt="Imagen"
                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CommentApp;

// src/components/Contacto.js
import React, { useState } from 'react';

const Contacto = () => {
  // Contenedor general: fondo claro, fuente Young Serif con tamaño aumentado y color de letra marrón
  const containerStyle = {
    minHeight: '100vh',
    backgroundColor: '#EAE6E1', // Fondo claro
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 20px',
    //fontFamily: 'Young Serif, serif',
    fontSize: '18px',
    color: '#583B1F',
  };

  // Estilo del formulario: fondo blanco, bordes redondeados y sombra sutil
  const formStyle = {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    width: '100%',
    marginBottom: '20px',
  };

  // Estilo para los inputs y textarea: bordes en marrón suave (#583B1F)
  const inputStyle = {
    width: '100%',
    padding: '10px',
    margin: '8px 0 16px 0',
    borderRadius: '5px',
    border: '1px solid #583B1F',
    fontSize: '18px',
    //fontFamily: 'Young Serif, serif',
    color: '#583B1F',
  };

  // Estilo para las etiquetas
  const labelStyle = {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    fontSize: '18px',
    //fontFamily: 'Young Serif, serif',
    color: '#583B1F',
  };

  // Estilo para el botón de envío: fondo verde, letras en blanco y fuente ajustada
  const buttonStyle = {
    backgroundColor: '#7E7B46', // Fondo del botón en verde
    color: '#fff',             // Letras en blanco
    padding: '12px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 'bold',
    width: '100%',
    transition: 'background-color 0.3s ease',
    //fontFamily: 'Young Serif, serif',
  };

  // Efecto hover para el botón (tono ligeramente diferente)
  const buttonHoverStyle = {
    backgroundColor: '#6B693F',
  };

  // Estilo para el recuadro del mensaje de respuesta: fondo verde, letras en blanco
  const messageStyle = {
    backgroundColor: '#7E7B46', // Mismo verde que el botón
    color: '#fff',             // Letras en blanco
    padding: '20px',
    borderRadius: '10px',
    fontSize: '18px',
    textAlign: 'center',
    marginTop: '10px',
    maxWidth: '500px',
    width: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontWeight: 'bold',
    //fontFamily: 'Young Serif, serif',
  };

  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    correo: '',
    mensaje: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage('');

    // Convertir los datos del formulario en una query string
    const queryString = new URLSearchParams(formData).toString();

    try {
      const response = await fetch('https://menuteca.es/backend/contacto.php?' + queryString, {
        method: 'GET'
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al enviar el mensaje. Por favor, contáctenos directamente a info@menuteca.es. ¡Gracias!');
      }

      const data = await response.json();
      setResponseMessage(data.message || 'Mensaje enviado correctamente. Gracias por contactar, ¡revisaremos su mensaje! 😊');

      // Reiniciar el formulario tras envío exitoso
      setFormData({
        nombre: '',
        apellidos: '',
        correo: '',
        mensaje: ''
      });
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ marginBottom: '20px' }}>Contáctanos</h1>
      <form onSubmit={handleSubmit} style={formStyle}>
        <div>
          <label htmlFor="nombre" style={labelStyle}>Nombre (obligatorio):</label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
            style={inputStyle}
          />
        </div>
        <div>
          <label htmlFor="apellidos" style={labelStyle}>Apellidos:</label>
          <input
            type="text"
            id="apellidos"
            name="apellidos"
            value={formData.apellidos}
            onChange={handleChange}
            style={inputStyle}
          />
        </div>
        <div>
          <label htmlFor="correo" style={labelStyle}>Correo electrónico (obligatorio):</label>
          <input
            type="email"
            id="correo"
            name="correo"
            value={formData.correo}
            onChange={handleChange}
            required
            style={inputStyle}
          />
        </div>
        <div>
          <label htmlFor="mensaje" style={labelStyle}>Mensaje (obligatorio):</label>
          <textarea
            id="mensaje"
            name="mensaje"
            value={formData.mensaje}
            onChange={handleChange}
            required
            style={{ ...inputStyle, minHeight: '120px' }}
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          style={isButtonHovered ? { ...buttonStyle, ...buttonHoverStyle } : buttonStyle}
          onMouseEnter={() => setIsButtonHovered(true)}
          onMouseLeave={() => setIsButtonHovered(false)}
        >
          {loading ? 'Enviando...' : 'ENVIAR'}
        </button>
      </form>
      {responseMessage && <div style={messageStyle}>{responseMessage}</div>}
    </div>
  );
};

export default Contacto;

// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Reemplaza estos valores con la configuración de tu proyecto Firebase
const firebaseConfig = {
    apiKey: "AIzaSyC5UUmXHYWwwxkIXy0df0ViWP_nZxDOjfc",
    authDomain: "menuteca-848e5.firebaseapp.com",
    projectId: "menuteca-848e5",
    storageBucket: "menuteca-848e5.firebasestorage.app",
    messagingSenderId: "1024839883063",
    appId: "1:1024839883063:web:84251a1a708764283762b2",
    measurementId: "G-NR3G7V5Z9M"
  };

  // Inicializa la app de Firebase
const app = initializeApp(firebaseConfig);

// Inicializa el servicio de autenticación y Firestore
const auth = getAuth(app);
const db = getFirestore(app);

// Configura el proveedor de Google
const googleAuthProvider = new GoogleAuthProvider();

export { app, auth, googleAuthProvider, db };
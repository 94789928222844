// Formulario.js
import React, { useState } from 'react';
import { useAuth } from '../AuthContext';

//import { Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Componente para seleccionar ubicación con Leaflet
/*
function LocationPicker({ onLocationSelect }) {
  const [position, setPosition] = useState([51.505, -0.09]);
  useMapEvents({
    click(e) {
      setPosition([e.latlng.lat, e.latlng.lng]);
      // Aquí podrías hacer reverse geocoding; por ahora se pasa una dirección sugerida
      onLocationSelect(e.latlng.lat, e.latlng.lng, "Dirección sugerida");
    }
  });
  return (
    <Marker
      draggable
      position={position}
      eventHandlers={{
        dragend: (e) => {
          const newPos = e.target.getLatLng();
          setPosition([newPos.lat, newPos.lng]);
          onLocationSelect(newPos.lat, newPos.lng, "Dirección sugerida");
        }
      }}
    >
      <Popup>Arrastra el marcador o haz click en el mapa</Popup>
    </Marker>
  );
}
*/

const Formulario = () => {
  const { user } = useAuth();
  const [modoEdicion, setModoEdicion] = useState(false);

  const [formData, setFormData] = useState({
    nombre: '',
    direccion: '',
    localidad: '',
    contacto: '',
    email: user ? user.email : '',
    telefono: '',
    sitio_web: '',
    enlace_reservas: '',
    twitter: '',
    facebook: '',
    instagram: '',
    google_maps: '',
    mostrar_google: 'ambos',
    tipo_cocina: '',
    variedades: [],
    menu_mediodia: '',
    menu_noche: '',
    menu_fin_semana_mediodia: '',
    menu_fin_semana_noche: '',
    incluye_menu: [],
    anotaciones: '',
    horarios_apertura: '',
    observaciones: '',
    latitud: '',
    longitud: '',
    beneficios: []
  });

  // Estados para imágenes
  const [imagenMenu, setImagenMenu] = useState(null);
  const [imagenAmbiente, setImagenAmbiente] = useState(null);
  const [imagenesComidas, setImagenesComidas] = useState([]);
  
  const [responseMessage, setResponseMessage] = useState('');

  // Estilos simples
  const containerStyle = { padding: '20px' };
  const cardStyle = { maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '10px' };
  const headingStyle = { textAlign: 'center', color: '#583B1F', fontWeight: 'bold', marginTop: '20px' };
  //const rowStyle = { marginBottom: '10px', display: 'flex', alignItems: 'center' };
  const rowStyle = {
    display: 'flex',
    flexDirection: 'column', // Para que label e input vayan uno debajo del otro
    marginBottom: '10px'
  };
  //const labelStyle = { flex: '0 0 30%', fontWeight: 'bold' };


  const labelStyle = {
    width: '100%',
    color: '#333',
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'block', // Hace que el label ocupe toda la línea
    marginBottom: '5px' // Opcional: añade espacio debajo del label
    ,     color: '#7E7B46'
  };
  
  const inputStyle = { flex: '1', padding: '8px', marginLeft: '10px' };
  const buttonStyle = { padding: '12px', backgroundColor: '#7E7B46', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', width: '100%', marginTop: '20px' };
  const fieldsetStyle = { border: '1px solid #ccc', borderRadius: '5px', padding: '15px', marginBottom: '20px' };
  //const legendStyle = { fontWeight: 'bold', marginBottom: '10px' };
  const legendStyle = {
    backgroundColor: '#EAE6E1',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#583B1F'//,
    //marginLeft: '10px'
    ,paddingLeft: '20px' ,
    borderRadius: '5px'
  };

  const paragraphStyle = {
    color: '#333',
    fontSize: '16px',
    marginBottom: '20px'
  };
  const textareaStyle = {
    width: '70%',
    minHeight: '80px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px',
    fontSize: '16px'
  };

  // Manejo de cambios en inputs
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      const currentArray = formData[name] || [];
      const updatedArray = checked
        ? [...currentArray, value]
        : currentArray.filter(item => item !== value);
      setFormData({...formData, [name]: updatedArray});
    } else {
      setFormData({...formData, [name]: value});
    }
  };

  // Manejo de inputs de archivos
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if(name === 'imagen_menu'){
      setImagenMenu(files[0]);
    } else if(name === 'imagen_ambiente'){
      setImagenAmbiente(files[0]);
    } else if(name === 'imagenes_comidas'){
      setImagenesComidas(Array.from(files).slice(0,3));
    }
  };

  // Cargar datos existentes (para modificar) mediante un endpoint de lectura
  const cargarDatosExistentes = () => {
    if(user && user.email){
      fetch(`https://menuteca.es/backend/lectura.php?email=${user.email}`)
        .then(res => res.json())
        .then(data => {
          if(data && data.registro){
            setFormData({...formData, ...data.registro});
            setModoEdicion(true);
          } else {
            alert("No se encontraron datos asociados a tu email.");
          }
        })
        .catch(err => {
          console.error(err);
          alert("Error al cargar los datos.");
        });
    }
  };

  // Ejemplo simplificado de selección en mapa (debes integrar una librería de mapas)
  const handleMapSelect = (lat, lng, address) => {
    setFormData({...formData, latitud: lat, longitud: lng, direccion: address});
  };

  // Envío del formulario con FormData para incluir archivos
  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if(Array.isArray(value)){
        dataToSend.append(key, JSON.stringify(value));
      } else {
        dataToSend.append(key, value);
      }
    });
    if(imagenMenu) dataToSend.append('imagen_menu', imagenMenu);
    if(imagenAmbiente) dataToSend.append('imagen_ambiente', imagenAmbiente);
    if(imagenesComidas.length > 0){
      imagenesComidas.forEach((file, index) => {
        dataToSend.append(`imagen_comida_${index+1}`, file);
      });
    }

    const apiKey_bbdd = 'mi_token_super_secreto';
    
    fetch('https://menuteca.es/backend/registro.php', {
      method: 'POST',
      headers: {
        'X-Authorization': `Bearer ${apiKey_bbdd}`
        // No se establece Content-Type ya que fetch lo configurará automáticamente para FormData
      },
      body: dataToSend
    })
    .then(res => res.json())
    .then(data => setResponseMessage(data.mensaje || data.error))
    .catch(err => {
      console.error(err);
      setResponseMessage("Error al enviar el formulario");
    });
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <h1 style={headingStyle}>Formulario de Registro</h1>
        <p style={paragraphStyle}>
          En 3 minutos puedes rellenar tu perfil y ser parte de los más de 100 menús promocionados en Barcelona, con más de 200 visitas diarias. 
          <div style={{ marginBottom: '10px' }}></div>
          Además, podrás modificar tus datos, imágenes y menús desde tu perfil cuando quieras.
        </p>
        {user && (
          <button type="button" style={buttonStyle} onClick={cargarDatosExistentes}>
            Cargar mis datos
          </button>
        )}
        <form onSubmit={handleSubmit}>
          {/* Identificación */}
          <fieldset style={fieldsetStyle}>
            <legend style={legendStyle}>Identificación</legend>
            <div style={rowStyle}>
              <label style={labelStyle}>Nombre del restaurante (obligatorio):</label>
              <input
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
                style={inputStyle}
              />
            </div>
           <div style={rowStyle}>
              <label style={labelStyle}>Dirección (obligatorio):</label>
              <input
                type="text"
                name="direccion"
                value={formData.direccion}
                onChange={handleChange}
                required
                style={inputStyle}
              />
              </div>
               {/*
<fieldset style={fieldsetStyle}>
  <legend style={legendStyle}>Dirección (obligatorio):</legend>
  <MapContainer
    center={[51.505, -0.09]}
    zoom={13}
    scrollWheelZoom={false}
    style={{ height: "300px", width: "100%" }}
  >
    <TileLayer
      attribution="&copy; OpenStreetMap contributors"
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <LocationPicker onLocationSelect={handleMapSelect} />
  </MapContainer>
</fieldset>*/}
              {/* Aquí podrías integrar un componente de mapa para seleccionar la ubicación */}
            
            <div style={rowStyle}>
              <label style={labelStyle}>Localidad (obligatorio):</label>
              <input
                type="text"
                name="localidad"
                value={formData.localidad}
                onChange={handleChange}
                required
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Nombre del contacto (uso interno):</label>
              <input
                type="text"
                name="contacto"
                value={formData.contacto}
                onChange={handleChange}
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Correo electrónico (obligatorio):</label>
              <input
                type="email"
                name="email"
                value={user ? user.email : formData.email}
                onChange={handleChange}
                required
                style={inputStyle}
                disabled={!!user}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Teléfono:</label>
              <input
                type="text"
                name="telefono"
                value={formData.telefono}
                onChange={handleChange}
                style={inputStyle}
              />
            </div>
          </fieldset>
  
          {/* Redes Sociales */}
          <fieldset style={fieldsetStyle}>
            <legend style={legendStyle}>Redes Sociales</legend>
            <div style={rowStyle}>
              <label style={labelStyle}>Sitio web:</label>
              <input
                type="url"
                name="sitio_web"
                value={formData.sitio_web}
                onChange={handleChange}
                placeholder="http://"
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Enlace para reservas:</label>
              <input
                type="url"
                name="enlace_reservas"
                value={formData.enlace_reservas}
                onChange={handleChange}
                placeholder="http://"
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Twitter:</label>
              <input
                type="text"
                name="twitter"
                value={formData.twitter}
                onChange={handleChange}
                placeholder="@"
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Facebook:</label>
              <input
                type="text"
                name="facebook"
                value={formData.facebook}
                onChange={handleChange}
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Instagram:</label>
              <input
                type="text"
                name="instagram"
                value={formData.instagram}
                onChange={handleChange}
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Google Maps (URL):</label>
              <input
                type="url"
                name="google_maps"
                value={formData.google_maps}
                onChange={handleChange}
                placeholder="http://"
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Mostrar puntuación y reseñas:</label>
              <select
                name="mostrar_google"
                value={formData.mostrar_google}
                onChange={handleChange}
                style={inputStyle}
              >
                <option value="ambos">Mostrar ambas</option>
                <option value="puntuacion">Sólo puntuación</option>
                <option value="reseñas">Sólo reseñas</option>
                <option value="ninguno">No mostrar</option>
              </select>
            </div>
          </fieldset>
  
          {/* Cocina y Menú */}
          <fieldset style={fieldsetStyle}>
            <legend style={legendStyle}>Cocina y Menú</legend>
            <div style={rowStyle}>
              <label style={labelStyle}>Tipo de cocina:</label>
              <select
                name="tipo_cocina"
                value={formData.tipo_cocina}
                onChange={handleChange}
                style={inputStyle}
              >
                <option value="">Selecciona una opción</option>
                <option value="catalana">Catalana</option>
                <option value="mediterranea">Mediterránea</option>
                <option value="gallega">Gallega</option>
                <option value="espanola">Española</option>
                <option value="local">Local</option>
                <option value="italiana">Italiana</option>
                <option value="turca">Turca</option>
                <option value="arabe">Árabe</option>
                <option value="argentina">Argentina</option>
                <option value="peruana">Peruana</option>
                <option value="venezolana">Venezolana</option>
                <option value="asiatica">Asiática</option>
                <option value="japonesa">Japonesa</option>
                <option value="china">China</option>
                <option value="india">India</option>
                <option value="clasica">Clásica</option>
                <option value="fusion">Fusión</option>
                <option value="marinera">Marinera</option>
                <option value="marisqueria">Marisquería</option>
                <option value="lounge">Lounge</option>
                <option value="tapas">Tapas</option>
                <option value="de_mercado">De mercado</option>
                <option value="comida_rapida">Comida rápida/urbana/casual</option>
                <option value="otro">Otro</option>
              </select>
            </div>
            {formData.tipo_cocina === 'otro' && (
      <div style={rowStyle}>
        <label style={labelStyle}>Especifica otro tipo de cocina:</label>
        <input
          type="text"
          name="otro_tipo_cocina"
          value={formData.otro_tipo_cocina || ''}
          onChange={handleChange}
          style={inputStyle}
          placeholder="Escribe el tipo de cocina..."
        />
      </div>
    )}
            <div style={{ marginBottom: '20px' }}>
              <label style={{ ...labelStyle, display: 'block', marginBottom: '8px' }}>
                Variedades:
              </label>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px'              }}>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    name="variedades"
                    value="menu_vegetariano"
                    checked={formData.variedades.includes("menu_vegetariano")}
                    onChange={handleChange}
                    style={{ marginRight: '7px' }}
                  />
                  Menú vegetariano
                </label>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    name="variedades"
                    value="menu_sin_gluten"
                    checked={formData.variedades.includes("menu_sin_gluten")}
                    onChange={handleChange}
                    style={{ marginRight: '7px' }}
                  />
                  Menú sin gluten
                </label>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    name="variedades"
                    value="menu_infantil"
                    checked={formData.variedades.includes("menu_infantil")}
                    onChange={handleChange}
                    style={{ marginRight: '7px' }}
                  />
                  Menú infantil
                </label>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    name="variedades"
                    value="menu_personalizable"
                    checked={formData.variedades.includes("menu_personalizable")}
                    onChange={handleChange}
                    style={{ marginRight: '7px' }}
                  />
                  Menú personalizable
                </label>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    name="variedades"
                    value="buffet_libre"
                    checked={formData.variedades.includes("buffet_libre")}
                    onChange={handleChange}
                    style={{ marginRight: '7px' }}
                  />
                  Buffe libre
                </label>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    name="variedades"
                    value="carta_alergenos"
                    checked={formData.variedades.includes("carta_alergenos")}
                    onChange={handleChange}
                    style={{ marginRight: '7px' }}
                  />
                  Carta de alérgenos
                </label>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    name="variedades"
                    value="opcion_take_away"
                    checked={formData.variedades.includes("opcion_take_away")}
                    onChange={handleChange}
                    style={{ marginRight: '7px' }}
                  />
                  Opción take away
                </label>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    name="variedades"
                    value="opcion_menu_premium"
                    checked={formData.variedades.includes("opcion_menu_premium")}
                    onChange={handleChange}
                    style={{ marginRight: '7px' }}
                  />
                  Opción menú premium
                </label>
              </div>
            </div>
          </fieldset>
  
          {/* Precios */}
          <fieldset style={fieldsetStyle}>
            <legend style={legendStyle}>Precios</legend>
            <div style={rowStyle}>
              <label style={labelStyle}>Menú mediodía (€):</label>
              <input
                type="text"
                name="menu_mediodia"
                value={formData.menu_mediodia}
                onChange={handleChange}
                placeholder="Ejemplo: 12.95"
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Menú noche (€):</label>
              <input
                type="text"
                name="menu_noche"
                value={formData.menu_noche}
                onChange={handleChange}
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Menú fin de semana mediodía (€):</label>
              <input
                type="text"
                name="menu_fin_semana_mediodia"
                value={formData.menu_fin_semana_mediodia}
                onChange={handleChange}
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Menú fin de semana noche (€):</label>
              <input
                type="text"
                name="menu_fin_semana_noche"
                value={formData.menu_fin_semana_noche}
                onChange={handleChange}
                style={inputStyle}
              />
            </div>
          {/*</fieldset>

          <fieldset style={fieldsetStyle}>*/}
            <div style={{marginBottom: '20px'}}></div>
          {/* El precio del menú incluye */}
            <legend style={legendStyle}>El precio del menú incluye:</legend>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  name="incluye_menu"
                  value="bebida"
                  checked={formData.incluye_menu.includes("bebida")}
                  onChange={handleChange}
                  style={{ marginRight: '5px' }}
                />
                Bebida
              </label>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  name="incluye_menu"
                  value="aperitivo"
                  checked={formData.incluye_menu.includes("aperitivo")}
                  onChange={handleChange}
                  style={{ marginRight: '5px' }}
                />
                Aperitivo
              </label>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  name="incluye_menu"
                  value="entrante"
                  checked={formData.incluye_menu.includes("entrante")}
                  onChange={handleChange}
                  style={{ marginRight: '5px' }}
                />
                Entrante
              </label>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  name="incluye_menu"
                  value="pan"
                  checked={formData.incluye_menu.includes("pan")}
                  onChange={handleChange}
                  style={{ marginRight: '5px' }}
                />
                Pan
              </label>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  name="incluye_menu"
                  value="principal"
                  checked={formData.incluye_menu.includes("principal")}
                  onChange={handleChange}
                  style={{ marginRight: '5px' }}
                />
                Principal
              </label>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  name="incluye_menu"
                  value="segundo"
                  checked={formData.incluye_menu.includes("segundo")}
                  onChange={handleChange}
                  style={{ marginRight: '5px' }}
                />
                Segundo
              </label>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  name="incluye_menu"
                  value="postre_cafe_te"
                  checked={formData.incluye_menu.includes("postre_cafe_te")}
                  onChange={handleChange}
                  style={{ marginRight: '5px' }}
                />
                Postre + café/té
              </label>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  name="incluye_menu"
                  value="postre"
                  checked={formData.incluye_menu.includes("postre")}
                  onChange={handleChange}
                  style={{ marginRight: '5px' }}
                />
                Postre/café/té
              </label>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  name="incluye_menu"
                  value="cafe_te"
                  checked={formData.incluye_menu.includes("cafe_te")}
                  onChange={handleChange}
                  style={{ marginRight: '5px' }}
                />
                Café/té
              </label>
            </div>
          </fieldset>
  {/* Ofértate: Programa de visibilidad */}
  <fieldset style={fieldsetStyle}>
    <legend style={legendStyle}>Ofértate y aumenta tu visibilidad e impacto en tus clientes</legend>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: '20px' }}>
      <label style={{ fontSize: '1.1em' }}>
        <input
          type="checkbox"
          name="beneficios"
          value="cashback"
          checked={formData.beneficios.includes("cashback")}
          onChange={handleChange}
          style={{ transform: 'scale(1.5)', marginRight: '10px' }}
        />
        Restaurante suscrito al programa 5% Cashback
        <br />
        <span style={{ fontSize: '0.9em', color: '#555' }}>
          Se solicitará la compra de un bono de 10€. Por cada unidad, se gestionará un consumo de 200€ <br /> (Ejemplo: 8 asistencias para 2 menús de 12€).
        </span>
      </label>
      <label style={{ fontSize: '1.1em' }}>
        <input
          type="checkbox"
          name="beneficios"
          value="ofertas"
          checked={formData.beneficios.includes("ofertas")}
          onChange={handleChange}
          style={{ transform: 'scale(1.5)', marginRight: '10px' }}
        />
        Ofertas del día
        <br />
        <span style={{ fontSize: '0.9em', color: '#555' }}>
          Participa en nuestro programa de reclamo de clientes: en días de baja asistencia podrás activar descuentos para posicionar tu anuncio y atraer comensales.
        </span>
      </label>
    </div>
  </fieldset>
          {/* Anotaciones para el equipo */}
          <fieldset style={fieldsetStyle}>
            <legend style={legendStyle}>Anotaciones para el equipo</legend>
            <div style={{ ...rowStyle, alignItems: 'flex-start' }}>
              {/*<label style={{ ...labelStyle, paddingTop: '5px' }}>Comentarios:</label>*/}
              <textarea
                name="anotaciones"
                value={formData.anotaciones}
                onChange={handleChange}
                placeholder="Ejemplo: El menú de mediodía ofrece una versión estándar y una gourmet."
                style={textareaStyle}
              />
            </div>
          </fieldset>
  
          {/* Horarios de apertura */}
          <fieldset style={fieldsetStyle}>
            <legend style={legendStyle}>Horarios de apertura</legend>
            <div style={rowStyle}>
              {/*<label style={labelStyle}>Horarios:</label>*/}
              <input
                type="text"
                name="horarios_apertura"
                value={formData.horarios_apertura}
                onChange={handleChange}
                placeholder="Ejemplo: Martes-Viernes 12:00-17:00, Sábado-Domingo 11:00-00:00"
                style={inputStyle}
              />
            </div>
          </fieldset>
  
          {/* Observaciones */}
          <fieldset style={fieldsetStyle}>
            <legend style={legendStyle}>Observaciones</legend>
            <div style={{ ...rowStyle, alignItems: 'flex-start' }}>
              <label style={{ ...labelStyle, paddingTop: '5px' }}></label>
              <textarea
                name="observaciones"
                value={formData.observaciones}
                onChange={handleChange}
                placeholder="Sugerencias, oportunidades, etc."
                style={textareaStyle}
              />
            </div>
          </fieldset>
  
          {/* Imágenes */}
          <fieldset style={fieldsetStyle}>
            <legend style={legendStyle}>Adjuntar imágenes</legend>
            <div style={rowStyle}>
              <label style={labelStyle}>Imagen Menú:</label>

              
              <input
                type="file"
                name="imagen_menu"
                accept="image/*"
                onChange={handleFileChange}
                style={inputStyle}
              />
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>Imagen Local/Ambiente:</label>
              <input
                type="file"
                name="imagen_ambiente"
                accept="image/*"
                onChange={handleFileChange}
                style={inputStyle}
              />
            </div>
             <div style={rowStyle}>
              <label style={labelStyle}>Imágenes platos (hasta 3):</label>

  {/* Si tienes URLs de imágenes anteriores, las muestras con <img> */}
  {formData.imagenes_comidas_url && formData.imagenes_comidas_url.length > 0 && (
    <div>
      <p>Imágenes actuales:</p>
      {formData.imagenes_comidas_url.map((imgUrl, idx) => (
        <img
          key={idx}
          src={imgUrl}
          alt={`Plato ${idx + 1}`}
          style={{ maxWidth: '100px', marginRight: '10px', marginBottom: '10px' }}
        />
      ))}
    </div>
  )}

              <input
                type="file"
                name="imagenes_comidas"
                accept="image/*"
                multiple
                onChange={handleFileChange}
                style={inputStyle}
              />
            </div>
          </fieldset>
  
          <button type="submit" style={buttonStyle}>
            {modoEdicion ? "Aplicar modificaciones" : "Enviar"}
          </button>
        </form>
        {responseMessage && (
          <p style={buttonStyle}>
            {responseMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default Formulario;

import React from 'react';
import Contacto from './components/Contacto';

const DescubreMenuteca = ({ onClose }) => {
  return (
    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '400px',
      height: '100%',
      backgroundColor: '#fff',
      border: '3px solid #fff',
      borderRadius: '15px',
      boxShadow: '2px 0 5px rgba(0,0,0,0.3)',
      zIndex: 2000,
      padding: '20px',
      overflowY: 'auto'
    }}>
      {/* Botón de cierre */}
      <button 
        onClick={onClose} 
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'none',
          border: 'none',
          fontSize: '1.2em',
          cursor: 'pointer'
        }}
      >
        X
      </button>
      
      {/* Contenido */}
      <h2 style={{ marginTop: '40px', marginBottom: '10px' }}>Descubre dónde comer hoy</h2>
      <p style={{ fontSize: '14px', color: '#555', marginBottom: '20px' }}>
        Descubre, degusta y disfruta. La guía definitiva de menús y eventos gastronómicos cerca de ti.
      </p>
      <p style={{ fontSize: '14px', color: '#555', marginBottom: '20px' }}>
        Conecta con los mejores restaurantes y menús del día. Encuentra inauguraciones, catas, eventos únicos o promociona tu lugar favorito.
      </p>
      
      {/* Componente de contacto */}
      <Contacto />
    </div>
  );
};

export default DescubreMenuteca;
